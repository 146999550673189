import React from "react";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router";
import PricingList from "./PricingList";
import "./Pricing.css";
import { useState, useEffect, useCallback } from "react";
import {
  createSubscription,
  createUpdateSubscription,
  getCompanies,
  getPlans,
} from "../../api/request";
import { isEmptyObject, sendAsFormData } from "../../utils/common";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../loader/Loader";
import Ripples from "react-ripples";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Multiselect } from "multiselect-react-dropdown";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import mixpanel from "mixpanel-browser";

export default function Pricing({ userprop }) {
  const [planList, setplanList] = useState([]);
  const [activePrice, setActivePrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addonModal, setAddonModal] = useState(false);
  const [bankdetailsModal, setBankdetailsModal] = useState(false);
  const [activePresent, setActivePresent] = useState(false);
  // const [loggedInUser, setLoggedInUser] = useState({});
  const [ErrorPopupModal, setErrorPopupModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getGTMPlane(3, 3);
  }, [planList]);

  useEffect(() => {
    getPlans().then((res) => {
      setplanList(res.data);

	 // Log query parameters from URL
      console.log("Query Parameters:", window.location.search);
      const queryParameters = new URLSearchParams(window.location.search);
      localStorage.setItem(
        "Utm_tags",
        decodeURIComponent(queryParameters.toString())
      );
      // Log utm_parameters to console
      console.log("utm_parameters:", queryParameters);

      setLoading(false);
      handleActivePrice(res.data);
    });
    getCompanies().then((res) => {
      setOptions(res.data);
      // const uniqueLengths = [...new Set(res.data.map(item => item.name))]
      // console.log(uniqueLengths);
    });
    if (localStorage.company_name === "") {
      console.log(localStorage.company_name);

      console.log("false");
      anonymousvist();
    } else {
      console.log(localStorage.company_name);

      clickbutton("Visited Subscription Page");
    }
  }, []);

  const handleActivePrice = async (plans) => {
    var temp = await plans.find((plan) => plan.active == true);
    if (temp && !isEmptyObject(temp)) setActivePrice(temp?.price);
  };

  const subscription = (id) => {
    // if (planList.find((plan) => plan.active == true)) {
    //   return 0;
    // }

    var data = {
      plan_id: id,
      utm_parameters: localStorage.getItem("Utm_tags") ?? "",
    };
    createSubscription(sendAsFormData(data)).then((res) => {
      var subslink = res.data.hosted_page_url;
      window.location.href = subslink;
    });
    setLoading(true);
  };

  const updateSubscription = (id) => {
    var data = {
      plan_id: id,
      utm_parameters: localStorage.getItem("Utm_tags") ?? "",
    };
    createUpdateSubscription(sendAsFormData(data)).then((res) => {
      console.log(res);
      console.log(res.success);
      if (res.success == false) {
        console.log(res.message);
        setLoading(false);
        setErrorMessage(res.message);
        setErrorPopupModal(true);
      } else {
        console.log("In true Loop");
        var subslink = res.data.hosted_page_url;
        window.location.href = subslink;
      }
    });
    setLoading(true);
  };

  function SampleNextArrow({ onClick }) {
    return (
      <div className="arrow arror_right" onClick={onClick}>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    );
  }

  function SamplePrevArrow({ onClick }) {
    return (
      <div className="arrow arror_left" onClick={onClick}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>
    );
  }

  const [slideIndex, setSlideIndex] = useState(0);

  window.dataLayer = [...window.dataLayer];
  function anonymousvist() {
    var deviceType = /Android/i.test(navigator.userAgent)
      ? "Android"
      : /iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? "IOS"
      : "Web";
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    mixpanel.track("Visited Subscription Page", {
      source: deviceType,
      //user_status: "anonymous",
      customer_type: "anonymous",
      customer_name: "anonymous",
      tenant_id: "anonymous",
      company_name: "anonymous",
      email_id: "anonymous",
      contact_detail: "anonymous",
    });
  }
  function clickbutton(eventName) {
    const customer_type = planList.some((items) => items.active);

    var deviceType = /Android/i.test(navigator.userAgent)
      ? "Android"
      : /iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? "IOS"
      : "Web";
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

    mixpanel.track(eventName, {
      source: deviceType,
      //user_status: click.user_status,
      customer_type: customer_type ? "Paid" : "Free",
      customer_name: localStorage.first_name + "" + localStorage.last_name,
      tenant_id: localStorage.tenant_id,
      company_name: localStorage.company_name,
      email_id: localStorage.email,
      contact_detail: localStorage.mobile_no,
    });
  }
  const getGTMPlane = useCallback(
    (current, next) => {
      if (planList.length != 0) {
        if (window.innerWidth > 1200) {
          const countGTM = [
            next,
            (next + 1) % planList.length,
            next - 1 < 0 ? planList.length - 1 : next - 1,
          ];

          const arrayItems = countGTM.map((item, index) => {
            return {
              item_id: planList[item].plan_unique_id,
              // item_list_name: planList[item].description,
              item_name: planList[item].description,
              affiliation: "Captainbiz", //
              //  // coupon: "SUMMER_FUN", // Optional
              //  // discount: 2.22, // Optional
              index: planList[item].id, // Item Index - index starts at 0, 1st product = 0
              item_brand: "Captainbiz", // Product Brand
              item_category: planList[item].name,
              //   item_category2: "Platinum",
              //   item_category3: "Diamond",
              //   item_list_id: "None",
              //   item_list_name: "Year_Plan",
              price: planList[item].price,
              //   item_variant: "Gold", // If Available
              //   //quantity: 1
            };
          });

          window.dataLayer.push({
            event: "view_item_list",
            ecommerce: {
              //item_list_id: "u-123455",// Whatever Item List ID available
              // item_list_name: "Year_Plan",
              items: [...arrayItems],
            },
          });
        } else {
          console.log("mobile");
          const GTMVlaues = [planList[current]];

          GTMVlaues.forEach((item, index) => {
            console.log(planList[item]);
          });
          // window.dataLayer = window.dataLayer || [];
          const countGTM = [next];

          const arrayItems = countGTM.map((item, index) => {
            return {
              item_id: planList[item].plan_unique_id,
              // item_list_name: planList[item].description,
              item_name: planList[item].description,
              affiliation: "Captainbiz", //
              //  // coupon: "SUMMER_FUN", // Optional
              //  // discount: 2.22, // Optional
              index: planList[item].id, // Item Index - index starts at 0, 1st product = 0
              item_brand: "Captainbiz", // Product Brand
              item_category: planList[item].name,
              //   item_category2: "Platinum",
              //   item_category3: "Diamond",
              //   item_list_id: "None",
              //   item_list_name: "Year_Plan",
              price: planList[item].price,
              //   item_variant: "Gold", // If Available
              //   //quantity: 1
            };
          });

          window.dataLayer.push({
            event: "view_item_list",
            ecommerce: {
              //item_list_id: "u-123455",// Whatever Item List ID available
              // item_list_name: "Year_Plan",
              items: [...arrayItems],
            },
          });
        }
        console.log(window.dataLayer);

        setSlideIndex(next);
      }
    },
    [planList]
  );

  const settings = {
    initialSlide: 3,
    centerMode: window.innerWidth > 1200,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth > 1200 ? 3 : 1,
    slidesToScroll: 1,
    beforeChange: getGTMPlane,

    //beforeChange: (current, next) => setSlideIndex(next),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const openAddonModal = () => {
    setAddonModal(true);
  };

  const [options, setOptions] = useState(["Option 1", "Option 2", "Option 3"]);

  // var activePrice = planList.find((plan) => plan.active == true);
  // console.log(activePrice);
  // if (plan.active == true) {
  //   activePrice = plan.price;
  //   console.log(activePrice);
  // }
  // planList.find((plan) => {
  //   plan.active == true ? plan.price : 0
  // })

  const [modalOpen, setModalOpen] = useState(true);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // FOR getting the UTM path for Appending to Register page
  const path = window.location.search;

  {
    errorMessage && <div className="error">{errorMessage}</div>;
  }

  return (
    <>
      <div className="pricing_cont">
        {loading && <Loader />}
        {ErrorPopupModal && (
          <ErrorPopup
            isOpen={modalOpen}
            onClose={closeModal}
            title={errorMessage}
          />
        )}

        <div className="actual_cards">
          <Slider {...settings}>
            {(planList && planList.length) > 0 ? (
              planList.map((plan, index) => {
                return (
                  <div
                    className={
                      index === slideIndex
                        ? "pricing_card active"
                        : "pricing_card"
                    }
                    key={index}
                  >
                    <div className="price_card_title">
                      <div className="card_title_flex">
                        <div className="card_name">
                          <h4>{plan.name}</h4>
                        </div>
                        <div className="card_price_cont">
                          <h3 className="card_price">
                            &#x20B9; {plan.price}
                            <span
                              style={{
                                fontSize: "15px",
                                color: "#555",
                                marginLeft: "5px",
                              }}
                            >
                              + GST
                            </span>
                            {/* <span className="strikePrice">
                              {plan.name === "tez"
                                ? ""
                                : plan.name === "copper"
                                ? ""
                                : plan.name === "silver"
                                ? ""
                                : plan.name === "gold"
                                ? "Rs 11999"
                                : plan.name === "platinum"
                                ? "Rs 19999"
                                : plan.name === "diamond"
                                ? "Rs 39999"
                                : ""}
                            </span> */}
                          </h3>
                        </div>
                      </div>
                      <span className="card_badge"> {plan.description} </span>
                      {plan.name === "gold" ? (
                        <span className="recommended"> Recommended </span>
                      ) : (
                        <span></span>
                      )}
                    </div>

                    <div className="pricing_features">
                      <div className="pricing_features_inner">
                        <PricingList list={" Issue purchase / sales invoice"} />
                        {/* <PricingList list={" 14 Days Free Trial"} /> */}
                        <PricingList list={" Order tracking"} />
                        <PricingList
                          list={" Editing a self-employment receipt"}
                        />
                        <PricingList list={" Current account tracking"} />
                        <PricingList
                          list={" Collection and payment tracking"}
                        />
                        <PricingList
                          list={" Cash and bank transaction tracking"}
                        />
                        <PricingList list={" Inventory tracking"} />
                        <PricingList list={" Customer check entry"} />
                        <PricingList list={" Customized invoice template"} />
                        <PricingList list={"  Android and iOS mobile use"} />
                        <PricingList
                          list={"  View stocks at party & product level"}
                        />
                        <PricingList
                          list={"  Scan Barcode & create invoices"}
                        />
                        <PricingList list={"  Cash & Bank Reconciliation"} />
                        <PricingList list={"  Expense management"} />
                      </div>
                    </div>

                    <div className="try_pay_cont">
                      {plan.active === true ? (
                        <a
                          className="activePlan"
                          // onClick={
                          // plan.addon_companies_allowed !== 0
                          // ? () => openAddonModal()
                          // : () => console.log("Actived")
                          // }
                        >
                          Active
                        </a>
                      ) : (
                        <>
                          {isEmptyObject(userprop) ? (
                            // <a href="https://panel.captainbiz.com/register">
                            // FOR UTM Appending to Register page
                            <a
                              href={`https://www.captainbiz.com/register${path}`}
                            >
                              Try
                            </a>
                          ) : (
                            <a
                              className={
                                plan.price < activePrice
                                  ? "downgrade"
                                  : "upgrade"
                              }
                              onClick={
                                // plan.addon_companies_allowed !== 0
                                //   ? () => openAddonModal()
                                // :
                                () => {
                                  window.dataLayer = window.dataLayer || [];
                                  window.dataLayer.push({
                                    event: "select_item",
                                    ecommerce: {
                                      //item_list_id: plan.plan_unique_id,
                                      //item_list_name: plan.description,
                                      items: [
                                        {
                                          item_id: plan.plan_unique_id, // This should be a unique Identifier
                                          item_name: plan.description,
                                          affiliation: "Captainbiz", //
                                          //coupon: "SUMMER_FUN", // Optional
                                          // discount: 2.22, // Optional
                                          index: plan.id, // Item Index - index starts at 0, 1st product = 0
                                          item_brand: "Captainbiz", // Product Brand
                                          //item_category:plan.name,
                                          //item_category2: "Platinum",
                                          //item_category3: "Diamond",
                                          //item_list_id: "None",
                                          //item_list_name: "Year_Plan",
                                          price: plan.price,
                                          item_variant: plan.name, // If Available
                                          // quantity: 1
                                        },
                                      ],
                                    },
                                  });

                                  console.log(window.dataLayer);

                                  if (plan.price < activePrice) {
                                    console.log("no");
                                  } else if (activePrice != 0) {
                                    clickbutton("Clicked Upgrade");

                                    updateSubscription(plan.id);
                                  } else {
                                    clickbutton("Clicked Buy Now");

                                    subscription(plan.id);
                                  }
                                }
                              }
                            >
                              {activePrice != 0
                                ? plan.price < activePrice
                                  ? "Unavailable"
                                  : "Upgrade"
                                : "Buy Now"}
                            </a>
                          )}
                        </>
                      )}
                    </div>

                    {/* For Early Payment Option  */}

                    <div className="try_pay_cont">
                      {plan.active === true ? (
                        <a
                          className="advancePayment"
                          onClick={
                            plan.active === true
                              ? () => updateSubscription(plan.id)
                              : () => console.log("Actived")
                          }
                        >
                          Early Payment
                        </a>
                      ) : (
                        <>
                          {isEmptyObject(userprop)
                            ? // <a href="https://panel.captainbiz.com/register">
                              // FOR UTM Appending to Register page
                              console.log("Buy Now")
                            : () => console.log("Advance Payment")}
                        </>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </Slider>
        </div>
      </div>
      {addonModal && (
        <div className="addonModal">
          <div className="innerAddon">
            <span
              className="closeaddon"
              onClick={() => {
                setAddonModal(false);
              }}
            >
              Close
            </span>
            <p>You can add on more companies with this plan!</p>
            <Multiselect
              isObject={true}
              options={options}
              selectionLimit="2"
              displayValue="name"
              onSelect={(event) => {
                for (let i = 0; i < event.length; i++) {
                  console.log(event[i].id);
                }
              }}
              // selectedValues={options.is_active == 1}
            />
          </div>
        </div>
      )}
      {bankdetailsModal && (
        <div className="outermodalCont">
          <div className="centerModalCont">
            <div className="bankDetailsCont"></div>
          </div>
        </div>
      )}
    </>
  );
}
