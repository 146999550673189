import React from "react";
import "./Footer.css";
import { BrowserRouter as Router, Link } from "react-router-dom";

let date = new Date().getFullYear();

export default function Footer({ classNameSpcl }) {
  return (
    <div className="footer_cont" id={classNameSpcl}>
      <div className="footer_inner">
        <div className="copyright">
          <p>
            Copyright {date}
            <a
              href={`https://www.logoinfosoft.com/?${
                localStorage.getItem("Utm_tags") ?? ""
              }`}
            >
              {" "}
              Logo Infosoft Business solution pvt ltd.
            </a>{" "}
            All Rights Reserved.
          </p>
        </div>

        <div className="footer_menu">
          <Link to={`/terms-of-use/?${localStorage.getItem("Utm_tags") ?? ""}`}>
            Terms of use
          </Link>
          <Link
            to={`/privacy-policy/?${localStorage.getItem("Utm_tags") ?? ""}`}
          >
            Privacy policy
          </Link>
          <Link
            to={`/information-and-consent/?${
              localStorage.getItem("Utm_tags") ?? ""
            }`}
          >
            Information and consent
          </Link>
        </div>
      </div>
    </div>
  );
}
