import axios from "axios";
import { getToken } from "../utils/common";

export const baseUrl = process.env.REACT_APP_SERVER_BASE_API_URL;

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: "Bearer " + getToken(),
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    console.log(err);
  }
);

export { axiosInstance };
