import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/request";
import { isEmptyObject } from "../../utils/common";

export default function Terms() {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleLogout = () => {
    logout();
    setUser("");
    // localStorage.clear();
    localStorage.removeItem("Capuser");
    localStorage.removeItem("token");
    localStorage.setItem("first_name", "");
    localStorage.setItem("last_name", "");
    localStorage.setItem("company_name", "");
    navigate("/");
  };

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("userProfile");
    if (!container.contains(e.target)) {
      container.classList.remove("show");
    } else {
      return;
    }
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("Capuser");
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setUser(foundUser);
    }

    const firstName = localStorage.getItem("first_name");
    const lastName = localStorage.getItem("last_name");
    const companyName = localStorage.getItem("company_name");

    setFirstName(firstName);
    setLastName(lastName);
    setCompanyName(companyName);
  }, []);

  return (
    <>
      <Navbar
        firstname={firstName}
        lastname={lastName}
        companyname={companyName}
        firstInit={firstName}
        lastInit={lastName}
        login={isEmptyObject(user) ? "login_btn" : "hide"}
        userActivity={isEmptyObject(user) ? "hide" : "welcome_cont"}
        handleLog={handleLogout}
      />
      <div className="footer_cards">
        <div className="whiteBack">
          <div className="head">
            <h3>Terms of Use</h3>
          </div>

          <div className="small_desc">
            <p>
              These Terms and Conditions of Website Use and Membership (“Terms
              of Use” or “Agreement”) is a digitally signed agreement between
              Logo Yazılım Sanayi ve Ticaret A.Ş. (“LOGO”), located at Gebze
              Organize Sanayi Bölgesi, Şahabettin Bilgisu Cad. No:609 Gebze,
              Kocaeli, and you (“CLIENT”) under the terms and conditions stated
              hereunder. LOGO and CLIENT shall be referred to as the “Party”
              individually and as the “Parties” collectively. BOTH PARTIES AGREE
              TO THESE TERMS OF USE AND ACKNOWLEDGE THAT THE TERMS AND
              CONDITIONS STATED HEREUNDER ARE LEGALLY BINDING. PLEASE READ THESE
              TERMS OF USE CAREFULLY BEFORE USING THE WEBSITE TO MAKE SURE THAT
              YOU AGREE TO ALL TERMS AND CONDITIONS STATED HEREUNDER. IF YOU DO
              NOT AGREE TO THESE TERMS OF USE, DO NOT USE THE WEBSITE AND/OR
              SERVICES.
            </p>
            <ol>
              <li>
                <b>SUBJECT</b>
                <br />
                LOGO provides a platform service (“Services”) on
                cloud.logo.com.tr (“Site”) for the sale and marketing of
                cloud-based software services accessed through websites and/or
                applications of LOGO or its business partners (“Business
                Partners”).
              </li>
              <li>
                <b>WEB SİTESİ GENEL KOŞULLARI</b>
                <br />
                <ol>
                  <li>
                    CLIENT (The term Client is used in the general sense
                    referring to anyone who uses the Website, regardless of
                    whether or not they purchase services) acknowledges that
                    he/she has read these Terms of Use, Website Privacy Policy
                    (“Privacy Policy”), all other terms and conditions related
                    to the Services and the Website and has all necessary
                    information related to the Website and the Services before
                    starting to use the Website. The Privacy Policy is an
                    integral part of this Terms of Use.
                  </li>
                  <li>
                    Using the Website and/or purchasing the services offered
                    through the Website means that CLIENT has read and agreed to
                    these Terms of Use and Privacy Policy.
                  </li>
                  <li>
                    Additional terms and conditions may apply for the use of
                    certain Services on the Website and/or any interaction
                    related to these Services.
                  </li>
                  <li>
                    CLIENT accepts full responsibility for all his/her
                    activities on the Website by using the Website in any way.
                    CLIENT shall be exclusively responsible for all legal
                    consequences of the communication carried out through the
                    Website, regardless of its purpose. LOGO takes no
                    responsibility and assumes no liability regarding such
                    communication.
                  </li>
                  <li>
                    LOGO shall have no liability in regard to tangible,
                    intangible, legal, financial consequences or indirect losses
                    such as loss of earnings or revenue arising from any use,
                    including erroneous, unlawful or illegal use of the services
                    offered through the Website or the sales and marketing
                    services offered by LOGO.
                  </li>
                  <li>
                    CLIENT is responsible for any content placed on, transmitted
                    to or sent via the Website, including those provided by the
                    CLIENT during purchases. CLIENT declares and warrants that
                    any information that he/she transmits/sends to the Website
                    is reliable, correct, does not violate the rights of third
                    parties, is not against the law, and he/she has the right to
                    transmit such information. CLIENT is responsible for all
                    consequences arising from the inaccuracy of such information
                    or content.
                  </li>
                  <li>
                    LOGO may require membership/registration to use the Website,
                    designate different membership categories or modify existing
                    categories. In such case;
                    <ol type="i">
                      <li>
                        During registration, ID and contact information such as
                        name, surname, ID/tax identification number, phone
                        number, e-mail address, address may be requested from
                        the CLIENT. At this stage, CLIENT is obliged to provide
                        correct information. LOGO assumes that the identity and
                        contact information transmitted to the Website for
                        registration are up-to-date, accurate and reliable.
                      </li>
                      <li>
                        A username and password will be assigned to the CLIENT
                        or created by the CLIENT during registration. CLIENT is
                        responsible for maintaining the confidentiality of user
                        name and password. CLIENT agrees to take all necessary
                        measures and notify LOGO immediately in the event of any
                        unauthorized use of his/her user name or password, or
                        any other breach of security. However, LOGO accepts no
                        liability for incorrect, unlawful or unauthorized use of
                        the username and password.
                      </li>
                      <li>
                        iii. CLIENT shall be responsible for all actions
                        performed in the user account. CLIENT agrees and
                        undertakes not to transfer or make his/her account,
                        username and/or other membership details available to
                        third parties and not to create an account on behalf of
                        anyone other than himself/herself on the Website.
                      </li>
                    </ol>
                  </li>
                  <li>
                    CLIENT agrees and undertakes not to collect any data on the
                    Website and not to access the database of the Website.
                    CLIENT or third parties may not collect, record, process
                    personal data of (other) clients through the Website and/or
                    use, copy, reproduce the Website database or attempt to gain
                    unauthorized access to client information.
                  </li>
                  <li>
                    Automatic and/or unauthorized access to the Website with the
                    help of robots and/or software and any action or attempt
                    which will pose a threat to or hinder the operation,
                    infrastructure or security of the Website is strictly
                    prohibited.
                  </li>
                  <li>
                    CLIENT agrees and undertakes not to use any tools, software
                    and other devices to interfere with the operation of the
                    Website and not to use software that sends more messages to
                    the LOGO server over a person's sending capacity within a
                    specified time frame.
                  </li>
                  <li>
                    Violating or attempting to violate the security of the
                    Website and the Services is prohibited. Criminal action may
                    be taken and a criminal case may be filed against those who
                    attempt such violations. The person or persons who committed
                    the violation may be held financially, legally and
                    criminally liable to the person and/or LOGO whose right has
                    been violated under the law.
                  </li>
                </ol>
              </li>

              <li>
                <b>WEBSITE CONTENT</b>
                <br />
                <ol>
                  <li>
                    The Website content, including logos, trademarks,
                    promotional materials, data files, texts, information,
                    opinions, advice, advertisements, photos, images and
                    software, may be partly uploaded directly by LOGO and partly
                    provided from other sources.
                  </li>
                  <li>
                    LOGO makes no warranties of and disclaims all legal
                    liabilities related to the accuracy and reliability of any
                    information, communication or commercial electronic messages
                    received from other websites or linked sites.
                  </li>
                  <li>
                    Third party information and quotations on the Website are
                    for promotional purposes. LOGO and/or the owners of this
                    content reserve the right to change the content and the
                    terms of Service without prior notice..
                  </li>
                  <li>
                    Unless otherwise approved by LOGO in writing or set forth
                    explicitly, CLIENT does not have the right to sell products
                    or services, carry out advertising or promotional activities
                    for commercial purposes or to make any such attempts by
                    using the Website pages and its content.
                  </li>
                  <li>
                    LOGO reserves the right, at its sole discretion, to
                    determine the form and/or design of the Website and its
                    contents and may offer the Website and Services with all
                    kinds of advertisements and/or promotions. Advertising and
                    promotions do not have to be directly related to the Website
                    and/or Services. LOGO may change the practices and tariffs
                    related to advertisements at any time without prior notice.
                  </li>
                  <li>
                    It is assumed that the CLIENT using the Website has
                    evaluated and undertaken the risks related to the content
                    and benefits, if any, received through the Website or the
                    Services.
                  </li>
                </ol>
              </li>
              <li>
                <b>SALES OF SERVICES</b>
                <ol>
                  <li>
                    A platform service is provided through the Website for the
                    re-sale of various services including cloud services
                    provided by LOGO and/or its Business Partners.
                  </li>
                  <li>
                    The services offered to the CLIENT through the Website are
                    exclusively for personal use. The services purchased may not
                    be used for commercial purposes, resold or transferred to
                    third parties.
                  </li>
                  <li>
                    Upon payment of the price related to the service, the CLIENT
                    will have the right to access and use the related services
                    or any related material or document in accordance with the
                    terms of the service agreement for the relevant service.
                  </li>
                  <li>
                    The use of the services offered on the Website is subject to
                    the original service/license agreement terms of the relevant
                    service and the conditions set and announced by the service
                    provider.
                  </li>
                  <li>
                    The services offered are provided through the relevant
                    service provider's infrastructure.
                  </li>
                </ol>
              </li>
              <li>
                <b>NO WARRANTY</b>
                <ol>
                  <li>
                    LOGO does not guarantee that the Website or any feature of
                    the Website will be secure or error-free, that any defects
                    will be corrected or that the server used to make the
                    Website available to the CLIENT and the Website itself or
                    the sub and parent sites/links owned by third parties will
                    be free of viruses or any other destructive content.
                  </li>
                  <li>
                    LOGO disclaims all warranties, express or implied, that the
                    Website will meet the CLIENT’s all expectations, purposes
                    and specific requirements, that it will be uninterrupted and
                    of sufficient quality, that It will provide commercial
                    benefits and profits to the CLIENT or that the cooperation
                    with Business Partners continue indefinitely or for a fixed
                    term. LOGO will not be liable to the CLIENT for any
                    consequential or indirect damages such as loss of profit
                    arising in connection with the Website.
                  </li>
                  <li>
                    CLIENT hereby agrees and undertakes to hold LOGO, its
                    partners, managers, representatives and/or employees
                    harmless against any damage claim and/or any other third
                    party claim in conflicts arising out of or in connection
                    with their use of the Website.
                  </li>
                  <li>
                    LOGO assumes no liability related to third party services
                    offered on the Website. Warranties given by the
                    manufacturers/importers and providers of relevant services
                    will apply. For the warranties given by the
                    manufacturers/importers other than LOGO, the Warranty period
                    is limited to the time specified by the
                    manufacturer/importer or the provider.
                  </li>
                  <li>
                    For the warranty conditions of third party
                    manufacturers/importers/providers, the warranty
                    certificates, introductory guides or brochures, if any,
                    provided with the service should be carefully examined. The
                    relevant manufacturer/importer or provider should be
                    consulted for any problems that may arise under the
                    warranty, if any. LOGO assumes no liability with respect to
                    such warranties.
                  </li>
                </ol>
              </li>
              <li>
                <b>INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS</b>
                <ol>
                  <li>
                    The intellectual and industrial property rights/copyrights
                    or use license rights of the Website and, unless otherwise
                    stated, of the logos, trademarks, domain names and other
                    elements on the Website belong to LOGO. The intellectual
                    property right of names, logos, trademarks, other materials
                    and content of third parties and Business Partners used on
                    the Website as part of the services offered belong to the
                    relevant proprietors. Unless specifically stated, the
                    conditions stated hereunder do not give the CLIENT the right
                    to use these elements or content or any other right related
                    to those.
                  </li>
                  <li>
                    No part of the Website may be copied, reproduced,
                    distributed, published, lent or disrupted by modifying the
                    contents in whole or in part in any way (methods such as
                    printing out, saving to disk, embedding into another site,
                    downloading in any other way). It is prohibited to copy and
                    use any Website elements or forms owned by LOGO or use them
                    as a model for reproduction.
                  </li>
                  <li>
                    CLIENT agrees that all kinds of user content provided or
                    sent to, used or created on the Website or sent to third
                    parties through the Website becomes non-confidential and
                    does not include any intellectual and industrial
                    rights/copyright/license rights. Otherwise, the User will be
                    exclusively responsible for such content.
                  </li>
                  <li>
                    In the event the service offered is a product or service
                    subject to intellectual or industrial property rights, the
                    proprietor of all rights including intellectual and
                    industrial property rights shall be determined in accordance
                    with the terms and conditions of the original service and/or
                    license agreements of the said services. License and
                    warranty terms of Third Party Services/Software are subject
                    to the original license/service agreements/terms of relevant
                    software..
                  </li>
                </ol>
              </li>
              <li>
                <b>COMMERCIAL COMMUNICATION</b>
                <ol>
                  <li>
                    CLIENT declares and accepts that he/she may receive
                    electronic messages or commercial electronic messages from
                    LOGO websites and/or websites not owned or controlled by
                    LOGO but operated by third parties, or may be contacted
                    through electronic means, whether of commercial nature or
                    not; provided that his/her consent/approval is obtained
                    where such consent/approval is mandatory for electronic
                    communication, and without a consent/approved where it is
                    not required.
                  </li>
                  <li>
                    CLIENT acknowledges that he/she knows that the
                    consent/approval required for commercial electronic
                    communication is not a prerequisite and/or obligation to use
                    the Website and/or use the Services. CLIENT has the right to
                    revoke the consent/approval given and opt out from receiving
                    commercial electronic messages at any time. The opt out
                    options are available in electronic messages sent by LOGO.
                    However, CLIENT will continue to receive electronic messages
                    containing information on the Services used, payments and
                    consents.
                  </li>
                </ol>
              </li>
              <li>
                <b>TERM AND TERMINATION</b>
                <ol>
                  <li>
                    This Agreement comes into effect on the date it is digitally
                    approved by the CLIENT and/or the CLIENT enters the Website.
                    CLIENT may stop using the Website at any time, without any
                    restrictions and without prior notice, unless otherwise
                    regulated under a separate agreement or Website content.
                    CLIENT is not entitled to make any claims against LOGO with
                    respect to the foregoing.
                  </li>
                  <li>
                    LOGO may terminate this Agreement unilaterally at any time,
                    without cause, compensation and prior notice and/or ban the
                    CLIENT's use of the Website, regardless of whether the
                    CLIENT actively uses the Website or not.
                  </li>
                  <li>
                    In the event (i) CLIENT violates or attempts to violate the
                    Terms of Use, Privacy Policy and/or any other term or policy
                    set by LOGO, regardless of whether or not the violation
                    occurs in full, (ii) CLIENT does not have the right to use
                    the Website due to legal restrictions, (iii) LOGO's
                    commercial relationship with the Business Partners providing
                    the third party services purchased by the CLIENT, (iv)
                    services received from the service providers are interrupted
                    for any reason, (v) access to the Website cannot be provided
                    due to legislation, decisions of administrative regulative
                    bodies or authorities and/or force majeure, (vi) or at its
                    sole discretion; LOGO may terminate or cancel this Agreement
                    and/or use of the Website immediately for good cause without
                    compensation and without prior notice and/or suspend or
                    terminate operation of or CLIENT’s access to the Website.
                  </li>
                  <li>
                    LOGO reserves the right to terminate the operation of the
                    Website and/or the sales and marketing services offered on
                    the Website without any prior notice.
                  </li>
                  <li>
                    LOGO’s failure to exercise or enforce any legal right or
                    injunction stated hereunder shall not be construed as a
                    waiver of LOGO’s rights under the Terms of Use and the
                    Privacy Policy or the law or imply acceptance of such
                    violation.
                  </li>
                </ol>
              </li>
              <li>
                <b>REVIEW / MODIFICATIONS TO THE TERMS OF USE</b>
                <ol>
                  <li>
                    The Website is frequently improved and updated by LOGO in
                    order to optimize the Website and/or Services for clients.
                    Changes may occur in the Website and/or Services offered
                    depending on such improvements and updates.
                  </li>
                  <li>
                    LOGO reserves the right to make changes in whole or in part
                    in the Website, the Services, Content of the Services, Terms
                    of Use and Privacy Policy, Business Partners, photos,
                    images, visual design or similar elements; to introduce
                    new/additional terms and new/additional Services; to
                    terminate the operation of or request a fee to use the
                    Website at any time without prior notice.
                  </li>
                  <li>
                    The changes will be posted on the Website to inform clients
                    of the terms of use and the changes will take effect on the
                    date of posting. CLIENT is responsible for reviewing the
                    Terms of Use periodically for any changes. Continued use of
                    the Website and Services following the posting of these
                    changes will mean that the changes have been accepted.
                  </li>
                </ol>
              </li>
              <li>
                <b>COLLECTION OF WEBSITE VISITING DATA AND COOKIES</b>
                <br />
                When the Website is visited, web servers automatically start
                collecting information to enable the Website to communicate with
                the visitor's computer. In addition, information such as the
                number of visits, preferred sections of the Website, IP
                addresses, domain type, browser type date and time, and
                navigation on the Website are monitored, controlled and stored.
                Using the Website means that the CLIENT allows LOGO to collect,
                process and store the specified information.
              </li>
              <li>
                <b>SUB AND PARENT LINKS</b>
                <br />
                The Website may contain sub and parent websites, services or
                pages not operated, controlled and/or owned by LOGO but operated
                by third parties and links/information may be provided on the
                Website related to those third party websites. LOGO does not
                assume any kind of warranty or specific liability with respect
                to the content, fitness, accuracy, security, privacy policies
                and/or uninterrupted communication related to these websites
                and/or applications. LOGO may not be held responsible for the
                personal data provided by the CLIENT to the said websites, the
                content or services used on these websites or the security and
                privacy policies and/or practices of the said sites. CLIENT
                shall comply with the terms of use and/or privacy policy and
                other notices of such third party websites. Privacy and security
                terms of these third party websites must be read prior to any
                action.
              </li>
              <li>
                <b>THIRD PARTY SERVICES</b>
                <ol>
                  <li>
                    Using the Website requires and Internet connection. The
                    videos, pictures, photographs, graphics, texts and images on
                    the Website are displayed with an Internet connection, and
                    the Internet usage fees are covered by the CLIENT's
                    wired/wireless network and/or mobile Internet package. LOGO
                    has no liabilities or obligations related to Internet usage
                    fees and/or other fees the CLIENT pays to the mobile
                    operator to use the Website.
                  </li>
                  <li>
                    In the event of provision of any service and/or
                    infrastructure from third parties, LOGO assumes no liability
                    with respect to the services and/or infrastructure of the
                    service providers. These conditions must be accepted berfore
                    using the Website.
                  </li>
                </ol>
              </li>
              <li>
                <b>RECORDS</b>
                <br />
                In case of any disputes arising from the Agreement or use of the
                Website, the electronic records including communication logs,
                computer logs, commercial books, commercial records, reports and
                documents kept by LOGO and e-mails, text messages (sms), fax
                messages sent to the CLIENT by LOGO shall constitute conclusive
                and exclusive evidence.
              </li>
              <li>
                <b>NOTICES</b>
                <br />
                It is acknowledged that e-mails, text messages (sms), fax
                messages sent by LOGO to the contact addresses provided by the
                CLIENT on the Website shall have the effects and legal
                consequences as duly served.
              </li>
              <li>
                <b>GOVERNING LAW</b>
                <br />
                Any disputes arising from the Agreement and/or use of the
                Website shall be governed by and construed in accordance with
                Turkish Law. Persons domiciled or accessing the Website from
                outside the borders of the Republic of Turkey agree that any
                disputes arising from the use of the Website shall be governed
                by and construed in accordance with Turkish Law and they they
                waive their rights arising from any other country regulations
                applicable.
              </li>
              <li>
                <b>COURT OF COMPETENT JURISDICTION</b>
                <br />
                Istanbul Çağlayan Courts and Execution Offices shall have
                exclusive jurisdiction with regard to the resolution of
                conflicts arising from the Agreement and/or use of the Website.
              </li>
              <li>
                <b>ASSIGNMENT</b>
                <br />
                CLIENT may not assign or transfer this Agreement or any or all
                of the rights and obligations under this Agreement to third
                parties without prior written consent of LOGO. LOGO reserves the
                right to assign or transfer this Agreement or the rights and
                obligations hereunder to third parties without prior consent.
              </li>
              <li>
                <b>SEVERABILITY</b>
                <br />
                In the event that a provision or part of a provision under this
                Agreement is found to be invalid or unenforceable by the
                competent court, the validity and enforceability of other
                provisions shall not be affected and shall continue to remain in
                effect.
              </li>
              <li>
                <b> INFORMATION AND CONTACT</b>
                <br />
                If you have any questions regarding the Website Terms of Use and
                Privacy Policy, please contact us via email at
                logocloud@logo.com.tr.
              </li>
            </ol>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
