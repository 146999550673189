import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const isEmptyObject = (obj) => {
  // console.log(obj);
  // console.log(typeof obj);
  // if (typeof obj !== "object" || obj == null) {
  //   return null;
  // }
  return Object.keys(obj).length === 0;
};

export const sendAsFormData = (data) => {
  let formdata = new FormData();
  for (let key in data) {
    formdata.append(key, data[key]);
  }

  return formdata;
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const showToast = (message) => {
  toast(message);
  return <ToastContainer />;
};
