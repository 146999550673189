import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/request";
import { isEmptyObject } from "../../utils/common";

export default function Privacy() {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleLogout = () => {
    logout();
    setUser("");
    // localStorage.clear();
    localStorage.removeItem("Capuser");
    localStorage.removeItem("token");
    localStorage.setItem("first_name", "");
    localStorage.setItem("last_name", "");
    localStorage.setItem("company_name", "");
    navigate("/");
  };

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("userProfile");
    if (!container.contains(e.target)) {
      container.classList.remove("show");
    } else {
      return;
    }
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("Capuser");
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setUser(foundUser);
    }

    const firstName = localStorage.getItem("first_name");
    const lastName = localStorage.getItem("last_name");
    const companyName = localStorage.getItem("company_name");

    setFirstName(firstName);
    setLastName(lastName);
    setCompanyName(companyName);
  }, []);

  return (
    <>
      <Navbar
        firstname={firstName}
        lastname={lastName}
        companyname={companyName}
        firstInit={firstName}
        lastInit={lastName}
        login={isEmptyObject(user) ? "login_btn" : "hide"}
        userActivity={isEmptyObject(user) ? "hide" : "welcome_cont"}
        handleLog={handleLogout}
      />
      <div className="footer_cards">
        <div className="whiteBack">
          <div className="head">
            <h3>Privacy Policy</h3>
          </div>

          <div className="small_desc">
            <p>
              This Website Privacy Policy <i>("Privacy Policy")</i> relates to
              the privacy of the data that you disclose and/or data obtained
              when you use cloud.logo.com.tr <i>("Website")</i> and related
              services <i>("Services")</i>. By using the Website and/or
              Services, you acknowledge that you have read and agreed to the
              Privacy Policy. If you think that you cannot fulfill the
              obligations stipulated in the Privacy Policy, please do not use
              the Website and Services. This Privacy Policy is an integral part
              of Website Terms of Use <i>(“Terms of Use”)</i>.
            </p>
            <ol>
              <li>
                <b>USER INFORMATION</b>
                <br />
                <br />
                CLIENT is responsible for any content placed on, transmitted to
                or sent via the Website, including those provided by the CLIENT
                during purchases. CLIENT declares and warrants that any
                information that he/she transmits/sends to the Website is
                reliable, correct, does not violate the rights of third parties,
                is not against the law, and he/she has the right to transmit
                such information. CLIENT is responsible for all consequences
                arising from the inaccuracy of such information or content.
              </li>
              <li>
                <b>DATA PROTECTION</b>
                <br />
                <br />
                LOGO shows maximum effort to ensure the security of all pages in
                the Website. Various technical and administrative measures are
                implemented to protect the privacy, security and integrity of
                the data uploaded, transmitted and/or saved to the Website. The
                information required for the payment of the services purchased
                from the Website (e.g. Credit card information) is not stored by
                LOGO. This information is transferred to contracted banks or
                BRSA approved payment service providers in order to complete the
                sales and payment transactions and the transfer of information
                is carried out through SSL-Based 128-Bit encoding.
              </li>
              <li>
                <b>PERSONAL DATA</b>
                <ol>
                  <li>
                    Pursuant to the Law no.6698 on Protection of Personal Data
                    (“Law”), any information related to an identified or
                    identifiable natural person is defined as personal data.
                    CLIENT’s Personal Data such as name, surname, tax number/ID
                    number, company title and tax office for sole
                    proprietorships, telephone number, address, e-mail address,
                    credit card information, IP information, sections visited,
                    domain type, browser type, date and time of visit etc. may
                    be transmitted, disclosed or stored at the Website when
                    he/she visits or uses the Website or purchases the services
                    offered at the Website. CLIENT accepts that Personal Data
                    provided, disclosed or saved will be visible to LOGO in
                    accordance with the conditions stipulated under the Terms of
                    Use and that any and all Personal Data provided or saved at
                    the Website will be deemed to be disclosed to LOGO.
                  </li>
                  <li>
                    These Personal Data may be processed by LOGO in whole or in
                    part by automated means provided that “it is directly
                    related to the conclusion or fulfillment of that contract,
                    processing of personal data belonging to the parties of a
                    contract is necessary, it is mandatory for the controller to
                    be able to perform his legal obligations” in accordance with
                    article 5 of the Law. These Personal Data may also be
                    processed for other purposes specified in the Privacy
                    Statement available on the homepage of the Website provided
                    that the CLIENT’s explicit consent is obtained.
                  </li>
                  <li>
                    Sensitive Personal Data: Unless required by law, LOGO does
                    not request from the CLIENT any data relating to race,
                    ethnic origin, political opinions, philosophical beliefs,
                    religion, sect or other beliefs of similar nature, clothing,
                    association, foundation or trade-union memberships, health,
                    sexual life, convictions and security measures as well as
                    biometric and genetic data. LOGO has no liability relating
                    to the information provided, saved or disclosed on the
                    Website.
                  </li>
                  <li>
                    The Personal Data Protection, Processing, Retention and
                    Destruction Policy, containing detailed information on
                    Personal Data and available under the “Personal Data” tab in
                    the corporate website www.logo.com.tr, is an integral part
                    of this Privacy Policy.
                  </li>
                </ol>
              </li>
              <li>
                <b>VIOLATION OF THE PRIVACY POLICY</b>
                <br />
                <br />
                LOGO reserves the right to reject, remove or delete any
                information existing in the system in the event of violation of
                the Privacy Policy or any such attempt, regardless of whether or
                not the violation occurs in full. This term shall also apply in
                the event of the CLIENT’s indirect violation of or an attempt to
                violate the Privacy Policy. LOGO’s failure to exercise or
                enforce any legal right or injunction stated hereunder shall not
                be construed as a waiver of LOGO’s rights under the Privacy
                Policy or the law or imply acceptance of such violation.
              </li>
              <li>
                <b>REVIEW / MODIFICATIONS TO THE PRIVACY POLICY </b>
                <br />
                <br />
                LOGO reserves the right to change the Privacy Terms or to add
                additional terms at any time without prior notice. The
                modifications will be announced on the Website to provide
                information on the the quality of the information collected
                during use, how it is used, when this information is shared with
                third parties and all necessary privacy conditions. Any such
                modification will be effective immediately upon posting on the
                Website. CLIENT is responsible for reviewing the Privacy Policy
                periodically for any changes. Continued use of the Website and
                Services following the posting of these changes to the Privacy
                Policy will mean that the changes have been accepted.
              </li>
              <li>
                <b>OPEN SYSTEM</b>
                <br />
                <br />
                CLIENT acknowledges and agrees that the Internet is not a secure
                environment, that communication in the Internet environment is
                risky and that any information including personal data,
                passwords etc. may be used in illegal acts by third parties.
                LOGO makes no warranties regarding the security of the Website
                and/or any malicious acts.
              </li>
              <li>
                <b>WEBSITE UTILITY PROGRAMS</b>
                <br />
                <br />
                Utility programs may be required to enable the CLIENT’s use of
                the Website. When such utility programs are used, data regarding
                the form and scope of utilization may be recorded in the Website
                database. Some “cookies” may be used to facilitate CLIENT’s use
                of the Website, some information can be sent to CLIENT or some
                CLIENT information may be collected through them.
              </li>
              <li>
                <b>INFORMATION AND CONTACT</b>
                <br />
                <br />
                If you have any questions regarding the Website Privacy Policy,
                please contact us via email at logocloud@logo.com.tr.
              </li>
            </ol>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
