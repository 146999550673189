import React from "react";
import "./Login.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { loginDetails, loginWithTenant } from "../../api/request";
import { sendAsFormData, showToast } from "../../utils/common";
import logocaptainBixz from "../../assets/logo-captainBiz.png";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";

export default function Login() {
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState("");
  const [dropdownError, setDropdownError] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loginUI, setLoginUI] = useState(true);
  const [companyName, setCompanyName] = useState();
  const [loginAccess, setLoginAccess] = useState();

  const [loginData, setLoginData] = useState([]);

  const navigate = useNavigate();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const LoginValid = () => {
    if (!isValidEmail(emailId)) {
      setErrors("Email is invalid");
      return;
    } else if (password === "") {
      return;
    } else {
      setErrors("");
      var data = { username: emailId, password: password };
      loginDetails(sendAsFormData(data)).then((res) => {
        if (res) {
          console.log(res);
          setLoginData(res.data.tenants);
          setLoginAccess(res.data.access_token);
          setLoginUI(false);
          setShowDropdown(true);
          return;
        }
        console.log("Erroe");
        // setPassword("");
        setErrors(true);
      });
    }
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const redirectEx = queryParameters.get("redirect_to");
  console.log("Redirect Val:", redirectEx);
  localStorage.setItem(
    "Utm_tags",
    decodeURIComponent(queryParameters.toString())
  );
  const LoginTenants = () => {
    if (companyName != null) {
      var data = { access_token: loginAccess, tenant_id: companyName };
      loginWithTenant(sendAsFormData(data)).then((res) => {
        console.log(res);
        if (res != null && res.success) {
          localStorage.setItem("Capuser", res.data.user);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("first_name", res.data.user.first_name);
          localStorage.setItem("last_name", res.data.user.last_name);
          localStorage.setItem("company_name", res.data.user.company.name);
          localStorage.setItem("email", res.data.user.email);
          localStorage.setItem("mobile_no", res.data.user.mobile_number);
          localStorage.setItem(
            "tenant_id",
            res.data.user.company.unique_tenant_id
          );
          if (redirectEx) {
            navigate(
              "/exclusive-plan" + localStorage.getItem("Utm_tags") ?? ""
            );
          } else {
            navigate(`/?${localStorage.getItem("Utm_tags") ?? ""}`);
          }
          showToast("Welcome");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        setDropdownError(true);
      });
      setDropdownError(false);
    } else {
      setDropdownError(true);
    }
  };

  return (
    <div className="login_cont">
      <Link to={`/?${localStorage.getItem("Utm_tags") ?? ""}`}>
        <img src={logocaptainBixz} alt="" className="login_logo" />
      </Link>
      {loginUI && (
        <div className="login_inner">
          <h3>Login</h3>
          <form action="">
            <div className="inputHold">
              <input
                type="email"
                placeholder="Email"
                value={emailId}
                autoComplete="true"
                onChange={(e) => setEmailId(e.target.value)}
              />
              {errors && <div className="error_valid">{errors}</div>}
            </div>

            <div className="inputHold">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors && <div className="error_valid">Invalid credentials</div>}
            </div>

            <div className="login_btn">
              <Ripples>
                <button type="button" onClick={() => LoginValid()}>
                  Login
                </button>
              </Ripples>
            </div>
          </form>
        </div>
      )}

      {showDropdown && (
        <div className="dropdown_cont">
          {dropdownError && <p className="dropErr">Please select a company</p>}
          <select
            name=""
            id="companySelection"
            onChange={(e) => setCompanyName(e.target.value)}
          >
            <option value="">Select Company</option>
            {(loginData && loginData.length) > 0 ? (
              loginData.map((logind, index) => {
                return (
                  <option value={logind.id} key={index}>
                    {logind.name}
                  </option>
                  // <option value="">Select Company</option>
                );
              })
            ) : (
              <div> Hello</div>
            )}
          </select>

          <div className="login_btn">
            <Ripples>
              <button type="button" onClick={() => LoginTenants()}>
                Login
              </button>
            </Ripples>
          </div>
        </div>
      )}
    </div>
  );
}
