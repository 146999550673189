export default function Maintenance() {
  return (
    <div className="maintenance">
      <article>
        <h1>We&rsquo;ll be back soon!</h1>
        <div>
          <p>
            Sorry for the inconvenience but we&rsquo;re performing some
            maintenance at the moment. We&rsquo;ll be back online shortly!
          </p>
          <p className="team">&mdash; Captainbiz Team</p>
        </div>
      </article>
    </div>
  );
}
