import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTable, usePagination } from "react-table";
import axios from "axios";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import "./Order.css";
import { isEmptyObject } from "../../utils/common";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ripples from "react-ripples";
import {
  downloadTransactions,
  getTransactions,
  logout,
} from "../../api/request";
import Footer from "../footer/Footer";
import { ColorRing } from "react-loader-spinner";

export default function Order() {
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(true);

  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const downloadPdf = (id) => {
    downloadTransactions(id).then((res) => {
      let alink = document.createElement("a");
      alink.href = res.data.url;
      alink.click();
    });
  };
  const paymentLink = (id) => {
    let alink = document.createElement("a");
    alink.href = id;
    alink.click();
  };

  const handleLogout = () => {
    logout();
    setUser("");
    // localStorage.clear();
    localStorage.removeItem("Capuser");
    localStorage.removeItem("token");
    localStorage.setItem("first_name", "");
    localStorage.setItem("last_name", "");
    localStorage.setItem("company_name", "");
    navigate("/");
  };

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("userProfile");
    if (!container.contains(e.target)) {
      container.classList.remove("show");
    } else {
      return;
    }
  });

  const columns = useMemo(
    () => [
      {
        Header: "Payment Date",
        accessor: "invoice_date",
        Cell: (tableProps) => (
          <span>{tableProps.row.original.invoice_date}</span>
        ),
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        Cell: (tableProps) => (
          <span>{tableProps.row.original.expiry_date}</span>
        ),
      },
      {
        Header: "Plan",
        accessor: "plan_name",
        Cell: (tableProps) => <span>{tableProps.row.original.plan_name}</span>,
      },
      {
        Header: "Amount",
        accessor: "price",
        Cell: (tableProps) => <span>{tableProps.row.original.price}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (tableProps) => <span>{tableProps.row.original.status}</span>,
      },
      {
        Header: "Invoice number",
        accessor: "invoice_number",
        Cell: (tableProps) => (
          <span>{tableProps.row.original.invoice_number}</span>
        ),
      },
      {
        Header: "Action",
        accessor: "download",
        Cell: (tableProps) => (
          <div className="experidown">
            <Ripples>
              <button
                className="downloadInvo"
                onClick={() => downloadPdf(tableProps.row.original.id)}
              >
                Download
              </button>
            </Ripples>
            {tableProps.row.original.payment_link != "" && (
              <Ripples>
                <button
                  className="payOnlyLink"
                  onClick={() =>
                    paymentLink(tableProps.row.original.payment_link)
                  }
                >
                  Pay
                </button>
              </Ripples>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const productsData = useMemo(() => [...products], [products]);
  const tableInstance = useTable(
    {
      columns,
      data: productsData,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // Forr pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    getTransactions().then((res) => {
      setProducts(res.data.transactions);
      setLoader(false);
      // console.log(res.data.transactions);
    });

    const loggedInUser = localStorage.getItem("Capuser");
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setUser(foundUser);
    }

    const firstName = localStorage.getItem("first_name");
    const lastName = localStorage.getItem("last_name");
    const companyName = localStorage.getItem("company_name");

    setFirstName(firstName);
    setLastName(lastName);
    setCompanyName(companyName);
  }, []);

  // console.log(products);
  return (
    <>
      <div className="order_wrapper">
        <Navbar
          firstname={firstName}
          lastname={lastName}
          companyname={companyName}
          firstInit={firstName}
          lastInit={lastName}
          login={isEmptyObject(user) ? "login_btn" : "hide"}
          userActivity={isEmptyObject(user) ? "hide" : "welcome_cont"}
          handleLog={handleLogout}
        />
        <div className="order_main">
          <div className="orderTable_cont">
            <div className="myOrder_tit">
              <h3>My Order</h3>
            </div>
            <div className="table_cont">
              <table {...getTableProps()} className="plistTable">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {loader ? (
                  <tbody className="no_data">
                    <tr>
                      <td colSpan={7}>
                        <ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={[
                            "#250188",
                            "#250188",
                            "#250188",
                            "#250188",
                            "#250188",
                          ]}
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    {isEmptyObject(products) ? (
                      <tbody className="no_data">
                        <tr>
                          <td colSpan={7}>No data to show</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </>
                )}
              </table>

              <div className="pagination">
                <div className="showing_entries">
                  {isEmptyObject(products) ? (
                    <p>
                      Showing {pageIndex} of {pageOptions.length} pages
                    </p>
                  ) : (
                    <p>
                      Showing {pageIndex + 1} of {pageOptions.length} pages
                    </p>
                  )}
                </div>

                <div className="btn_conts">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className={
                      !canPreviousPage
                        ? "pagination_btn disable"
                        : "pagination_btn"
                    }
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className={
                      !canNextPage ? "pagination_btn disable" : "pagination_btn"
                    }
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer classNameSpcl="OrderFoot" />
      </div>
    </>
  );
}
