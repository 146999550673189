import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Pricing from "../components/pricing/Pricing";
import Title from "../components/title/Title";
import { isEmptyObject } from "../utils/common";
import { autoLogin, logout } from "../api/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IntercomProvider, useIntercom } from "react-use-intercom";

export default function Home() {
  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const notify = () => toast("Successfull Login");

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    setUser("");
    // localStorage.clear();
    localStorage.removeItem("Capuser");
    localStorage.removeItem("token");
    localStorage.setItem("first_name", "");
    localStorage.setItem("last_name", "");
    localStorage.setItem("company_name", "");
    window.location.reload();
  };

  useEffect(() => {
    // redirect if hash login route
    if (window.location.href.indexOf("#!/auto-login") > -1) {
      var trucateString = navigate(
        window.location.href.split("#!")[1].replace("#!/", "")
      );
    }

    const loggedInUser = localStorage.getItem("Capuser");
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setUser(foundUser);
      // notify();
    }

    const firstName = localStorage.getItem("first_name");
    const lastName = localStorage.getItem("last_name");
    const companyName = localStorage.getItem("company_name");

    setFirstName(firstName);
    setLastName(lastName);
    setCompanyName(companyName);
  }, []);

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("userProfile");
    if (!container.contains(e.target)) {
      container.classList.remove("show");
    } else {
      return;
    }
  });

  const INTERCOM_APP_ID = "gk57bsy2";

  const onHide = () => console.log("Intercom did hide the Messenger");
  const onShow = () => console.log("Intercom did show the Messenger");

  return (
    <>
      <ToastContainer />
      <Navbar
        firstname={firstName}
        lastname={lastName}
        companyname={companyName}
        firstInit={firstName}
        lastInit={lastName}
        login={isEmptyObject(user) ? "login_btn" : "hide"}
        userActivity={isEmptyObject(user) ? "hide" : "welcome_cont"}
        handleLog={handleLogout}
      />
      <div className="main_cont">
        <Title/>
        <Pricing userprop={user} />
        <Footer />
      </div>
    </>
  );
}
