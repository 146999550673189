import React from "react";
import "./Thankyou.css";
import arrowRight from "../../assets/arrow-right.svg";
import anim from "../../assets/Animation.gif";
import anim2 from "../../assets/Diwali.gif";
import greenTick from "../../assets/Custom.svg"; // Import the custom SVG
import goldCoin from "../../assets/Coin.png";

export default function Thankyou() {
  return (
    <div className="thankyou-page">
      <div className="thankYou_cont">
        <div className="whiteRectangle">
          <div className="innerThankYou">
            <div id="loadCont">
              <img src={anim2} alt="" />
              <div className="customSvgContainer">
                <img src={greenTick} alt="Custom SVG" />
              </div>
            </div>

            <div className="thankText">
              <h1>Thank You!</h1>
              <p>
                Your payment is complete and confirmation email has been sent to
                your registered email address.
              </p>
            </div>
            <br />
            <br />

            {/* Button container to place both buttons in a single row */}
            <div className="buttonContainer">
              <button className="thankBtn" id="winCoinButton">
                <a href={process.env.REACT_APP_PANEL_URL}>
                  <div className="buttonTextContainer">Login</div>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
