import React from 'react'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function PricingList({list}) {
  return (
    <p> 
        <FontAwesomeIcon icon={faCircleCheck} className="listCheck"/>
        {list}
    </p>
  )
}
