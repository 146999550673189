import { getToken } from "../utils/common";
import { axiosInstance } from "./config";

export const autoLogin = (token, tenantId) => {
  return axiosInstance.post(`/login/${token}/${tenantId}`);
};

export const getPlans = () => {
  return axiosInstance.get("/plans");
};

export const createSubscription = (planId) => {
  let token = getToken();
  axiosInstance.defaults.headers.post["Authorization"] = "Bearer " + token;
  return axiosInstance.post("/create_subscription", planId);
};

export const createUpdateSubscription = (planId) => {
  let token = getToken();
  axiosInstance.defaults.headers.post["Authorization"] = "Bearer " + token;
  return axiosInstance.post("/update_subscription", planId);
};

export const loginDetails = (username, password) => {
  return axiosInstance.post("/login", username, password);
};

export const loginWithTenant = (access_token, tenant_id) => {
  return axiosInstance.post("/login_with_tenant", access_token, tenant_id);
};

export const logout = () => {
  return axiosInstance.post("/logout");
};

export const getCompanies = () => {
  return axiosInstance.get("/get_companies");
};

export const getTransactions = () => {
  return axiosInstance.get("/transactions");
};

export const downloadTransactions = (invoiceId) => {
  return axiosInstance.get(`/invoice/download/${invoiceId}`);
};
