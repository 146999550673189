import { Axios } from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { autoLogin } from "../../api/request";
import { showToast } from "../../utils/common";

export default function AutoLogin() {
  const { token, tenantId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    autoLogin(token, tenantId).then((res) => {
      console.log(res);
      if (res != null && res.success) {
        localStorage.setItem("Capuser", res.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("first_name", res.data.user.first_name);
        localStorage.setItem("last_name", res.data.user.last_name);
        localStorage.setItem("company_name", res.data.user.company.name);
        navigate("/");
        showToast("Welcome");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return;
      }
      navigate("/login");
    });
  });

  return <div></div>;
}
