import React from "react";
import logocaptainBixz from "../../assets/logo-captainBiz.png";
import "./Title.css";


export default function Title({tokenCheck}) {
  return (
    <div className="tit_cont">
      <div className="tit_inner">
        <div className="sec_logo_cont">
            {/* <img src={logocaptainBixz} alt="" /> */}
        </div>
        <div className="small_tit_cont">
            <h1>The most suitable packages for your needs</h1>
            {/* <h1>{tokenCheck}</h1> */}
        </div>
      </div>
    </div>
  );
}
