import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/request";
import { isEmptyObject } from "../../utils/common";

export default function Information() {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleLogout = () => {
    logout();
    setUser("");
    // localStorage.clear();
    localStorage.removeItem("Capuser");
    localStorage.setItem("token");
    localStorage.setItem("first_name", "");
    localStorage.setItem("last_name", "");
    localStorage.setItem("company_name", "");
    navigate("/");
  };

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("userProfile");
    if (!container.contains(e.target)) {
      container.classList.remove("show");
    } else {
      return;
    }
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("Capuser");
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setUser(foundUser);
    }

    const firstName = localStorage.getItem("first_name");
    const lastName = localStorage.getItem("last_name");
    const companyName = localStorage.getItem("company_name");

    setFirstName(firstName);
    setLastName(lastName);
    setCompanyName(companyName);
  }, []);

  return (
    <>
      <Navbar
        firstname={firstName}
        lastname={lastName}
        companyname={companyName}
        firstInit={firstName}
        lastInit={lastName}
        login={isEmptyObject(user) ? "login_btn" : "hide"}
        userActivity={isEmptyObject(user) ? "hide" : "welcome_cont"}
        handleLog={handleLogout}
      />
      <div className="footer_cards">
        <div className="whiteBack">
          <div className="head">
            <h3>Informative Text</h3>
          </div>

          <div className="small_desc">
            <p>
              Logo Yazılım Sanayi ve Ticaret A.Ş. (“Logo”), provides a platform
              service on
              <a href="cloud.logo.com.tr">cloud.logo.com.tr</a> (“Website”), for
              the sale and marketing of cloud-based software services accessed
              through websites and/or applications of Logo or its business
              partners (“Business Partners”).
            </p>
            <p>
              This Informative Text has been prepared in accordance with article
              10 of the Law no.6698 on Protection of Personal Data (“Law”) in
              order to inform natural persons (“Data Subject”) on the processes,
              means and purposes of collection, processing, retention,
              protection and destruction of their Personal Data as well as their
              rights under the Law and the means of exercising such rights, in
              cases where Logo acts as data controller during provision of the
              Services and the Website to users.
            </p>
            <b>Data Controller :</b> Logo Yazılım Sanayi ve Ticaret A.Ş.
            <br />
            <b>Address :</b> Gebze Organize Sanayi Bölgesi, Şahabettin Bilgisu
            Cad. No:609 Gebze/Kocaeli
            <br />
            <b>MERSİS No :</b> 0609012207400023
            <br />
            <b>Trade Registration No :</b> 12750
            <br />
            <b>Tel :</b> +90 (262) 679 80 00
            <br />
            <b>Website :</b> www.logo.com.tr
            <br />
            <br />
            <b>1. Personal Data</b>
            <p>
              Pursuant to the provisions of the law, any information related to
              an identified or identifiable natural person is defined as
              personal data. Your Personal Data such as your name, surname, tax
              number/ID number, company title and tax office for sole
              proprietorships, telephone number, address, e-mail address, credit
              card information, IP information, sections accessed, domain type,
              browser type, date and time of access etc. may be transmitted,
              disclosed or stored at the Website when you access or use the
              Website or purchase the services offered at the Website..
            </p>
            <br />
            <b>2. How and For What Purposes is Your Data Processed?</b>
            <br />
            <b>&nbsp;2.1. Processing in accordance with the Law</b>
            <p>
              We may process your Personal Data, which you have transmitted,
              disclosed, stored at the Website or which is collected
              automatically in accordance with the operating principles of the
              Website or from third party organizations, in whole or in part by
              automated means provided that “it is directly related to the
              conclusion or fulfillment of that contract, processing of personal
              data belonging to the parties of a contract is necessary, it is
              mandatory for the controller to be able to perform his legal
              obligations” in accordance with article 5 of the Law. Accordingly,
              we may use, process, store or classify Personal Data in the
              databases, use them in accordance with the purposes of the works
              and/or Services in our field of activity, and use the data in the
              database for the purposes related to the Services provided on the
              Website and the provision of these Services, such as:
            </p>
            <ol type="i">
              <li>
                {" "}
                Enabling access to the Website and Services, enabling use of the
                Website in accordance with the Terms of Use,
              </li>
              <li>
                {" "}
                Enabling utilization of products and services offered by
                Business Partners from time to time, including but not limited
                to those available and/or announced on the Website,
              </li>
              <li>
                Purchase transactions; in the event of purchasing Business
                Partner Services, subscription to such services,
              </li>
              <li>
                Ensuring the security of the Website and/or Services; detection
                and prevention of potential security risks; prevention and, when
                necessary, suspension of use in case of violation of the Terms
                of Use and Privacy Policy and/or applicable legislation,
              </li>
              <li>
                Carrying out activities such as advertising, marketing etc.,
                promotion of existing or new products and/or services and/or
                features of the Website, in accordance with the commercial
                e-mail consent previously obtained when necessary,
              </li>
              <li>
                Enabling communication within the scope of the use of the
                Website, provision of support services,
              </li>
              <li>
                In the event of a merger, demerger, transfer of the company in
                whole; execution of the results arising from this legal
                procedure,
              </li>
              <li>
                Management of judicial/administrative procedures, responding to
                requests from public institutions and organizations, performing
                legal obligations in accordance with legislative regulations,
                settlement of legal disputes,
              </li>
              <li>
                Responding to applications, notifications, questions and/or
                requests, conducting necessary internal research in the response
                process, keeping application records.
              </li>
            </ol>
            <p>
              Your personal data may be transferred, disclosed, and transmitted
              to Business Partners, Legally Authorized Private Entities, Payment
              Service Providers or Banks, Public Institutions and Organizations
              at home or abroad in accordance with the purposes stated
              hereunder.
            </p>
            &gt;
            <p>
              We may match the data collected through different methods or at
              different times, such as data collected online and offline, and
              combine it with data from other sources, such as third parties,
              provided that it is not used for any other purpose or the scope
              specified hereunder.
            </p>
            <b>2.2. Processing in accordance with Explicit Consent </b>
            <br />
            <p>
              With your explicit consent in accordance with the legislation
              provisions, we may use, process, store your personal data and
              transfer, disclose or transmit them to Business Partners, Service
              Providers, Consultants, Logo Group Companies in accordance with
              the Law for purposes such as:
            </p>
            <ol type="i">
              <li>Development, promotion, advertisement of the Website,</li>
              <li>Maintaining commercial relations with business partners,</li>
              <li>Reporting purposes within the framework of collaboration,</li>
              <li>Communication purposes,</li>
              <li>Promotion and marketing of Logo Group Companies products,</li>
              <li>Website use analysis, keeping statistics,</li>
              <li>
                Development of business strategies and plans of the company,
              </li>
              <li>Communication for satisfaction measurement surveys,</li>
              <li>Conducting market research.</li>
            </ol>
            <b>
              3. What are Our Methods and Legal Grounds for Collection of
              Personal Data?
            </b>
            <p>
              We may collect and process your Personal Data through written,
              oral or electronic means such as filling out the Website
              membership form, accessing the Website via Logo or Logo Group
              Companies’ products and/or Website cookies, in accordance with the
              provisions under our Policy on Protection, Retention and
              Destruction of Personal Data, the Law and applicable legislation.
              The Personal Data collected may be stored on software, cloud,
              central server, Company and/or Website database. Our Personal Data
              Collection process may be carried out through: i) the Website,
              digital mediums or software including e-mail; or ii) contracts,
              applications, forms, call center, remote support, Website cookies,
              telephone etc.
            </p>
            <b>4. What are Your Rights as a Data Subject?</b>
            <p>
              Pursuant to article 11 of the Law, you have the right to apply to
              our Company as the Data Subject and;
            </p>
            <ul type="disc">
              <li>to learn whether your Personal Data is processed,</li>
              <li>
                to request information if your Personal Data is processed,
              </li>
              <li>
                to learn the purpose of processing of your Personal Data and
                whether such data is used for intended purposes,
              </li>
              <li>
                to know the third parties to whom your Personal Data is
                transferred within the country or abroad,
              </li>
              <li>
                to request rectification of the incomplete or inaccurate data,
                if any, and notification of third parties, to whom Personal Data
                was transferred, of such rectification,
              </li>
              <li>
                to request erasure or destruction of Personal Data upon
                disappearance of all reasons which require processing of
                Personal Data, despite being processed under the provisions of
                the Law and other applicable laws, and to request notification
                of such operation to third parties to whom Data Subject’s
                Personal Data has been transferred,
              </li>
              <li>
                to object to the processing, exclusively by automatic means, of
                your Personal Data leading to an unfavorable consequence for the
                data subject,
              </li>
              <li>
                to request compensation for the damage arising from the unlawful
                processing of your Personal Data.
              </li>
            </ul>
            <p>
              However, in accordance with the provision under article 28/2 of
              the Law, you may not exercise the rights listed above, excluding
              the right to demand compensation, in the following cases:
            </p>
            <ul type="disc">
              <li>
                Where processing of Personal Data is necessary for prevention of
                a criminal act or crime investigation.
              </li>
              <li>
                Where Personal Data disclosed to public by the Data Subject
                himself is processed.
              </li>
              <li>
                Where processing of Personal Data is necessary for inspection or
                regulatory duties and disciplinary investigation or prosecution
                carried out by public institutions and organizations as well as
                by professional associations with public institution status
                assigned and authorized by the law.
              </li>
              <li>
                Where processing of Personal Data is necessary for protection of
                the State’s economic and financial interests with regard to
                budgetary, tax-related and financial issues.
              </li>
            </ul>
            <p>
              In the event you wish to exercise your rights; you may fill in the
              application form and send your request to the Company in writing
              or using registered electronic mail (KEP), secure electronic
              signature, mobile signature or through your e-mail address
              previously registered to the system of the Company.
            </p>
            <p>
              For written applications, you may send the signed application form
              filled in Turkish to the company located at “Gebze Organize Sanayi
              Bölgesi, Şahabettin Bilgisu Cad. No: 609 Gebze/Kocaeli” through a
              notary public’s office or deliver it by hand or have it delivered
              by a proxy to the same address.
            </p>
            <p>
              For applications to be made by other means mentioned above
              (electronically), you can fill in the form in Turkish and send it
              to our addresses listed below:
            </p>
            <p>
              <b>Electronic Mail (KEP):</b> logoyazilim@hs01.kep.tr <br />
              <b>E-mail:</b> logocloud@logo.com.tr
            </p>
            <p>
              It is mandatory to submit the documents authenticating your
              identity, the documents supporting your request, if any, and if
              you wish to exercise such right through proxy, the notarized copy
              of the proxy statement with specific authorization for the issue,
              along with the form.
            </p>
            <p>
              Your requests submitted with a form shall be responded free of
              charge within the shortest time possible depending on the nature
              of the request and within thirty days at the latest. However, in
              the event the procedure requires any additional costs, a fee may
              be charged based on the tariff determined by the Board.
            </p>
            <p>
              In the course of an application; in the event of sharing
              incomplete or inaccurate information, failure to express the
              request clearly, lack of or improper submission of support
              documents, failure to attach a copy of the proxy statement for
              applications through proxy, we may encounter difficulties meeting
              your requests which may result in delays in the investigation
              process. Therefore, it is important to comply with the foregoing
              while exercising your rights. In such cases, our company shall not
              be responsible for any delays. All legal rights of our company are
              reserved in case of any incorrect, contrary to facts/law and
              malicious applications.
            </p>
            <p>
              For further information on our Personal Data processes, please
              refer to Logo Group Companies Personal Data Policy available at{" "}
              <a href="https://www.logo.com.tr/logo-kisisel-veriler">
                https://www.logo.com.tr/logo-kisisel-veriler
              </a>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
