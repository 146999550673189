import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import AutoLogin from "./components/autologin/AutoLogin";
import Information from "./components/information/Information";
import Login from "./components/login/Login";
import Order from "./components/orders/Order";
import Privacy from "./components/privacy/Privacy";
import SpecialPricing from "./components/specialpricing/SpecialPricing";
import Terms from "./components/terms/Terms";
import Thankyou from "./components/thankyou/Thankyou";
import Top from "./components/top/Top";
import Home from "./moduleComponents/Home";
import Maintenance from "./moduleComponents/Maintenance";
import { useEffect, useState } from "react";
function App() {
  const INTERCOM_APP_ID = "gk57bsy2";

  const onHide = () => console.log("Intercom did hide the Messenger");
  const onShow = () => console.log("Intercom did show the Messenger");

  const [maintenance, setMaintenance] = useState(false);
  const mainVal = process.env.REACT_APP_MAINTENANCE_VAR;

  useEffect(() => {
    if (mainVal == "true") {
      setMaintenance(true);
    }
  }, [mainVal]);

  return (
    // <Router basename="/subscription-portal">
    <>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        onHide={onHide}
        onShow={onShow}
        autoBoot
      >
        <Router>
          <Top>
            <Routes>
              {maintenance && <Route path="/" element={<Maintenance />} />}
              {!maintenance && (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/terms-of-use" element={<Terms />} />
                  <Route path="/privacy-policy" element={<Privacy />} />
                  <Route
                    path="/information-and-consent"
                    element={<Information />}
                  />
                  {/* <Route path="/" element={<Home />} /> */}
                  {/* <Route path="/exclusive-plan" element={<SpecialPricing />} /> */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/order" element={<Order />} />
                  <Route
                    path="/auto-login/:token/:tenantId"
                    element={<AutoLogin />}
                  />
                  <Route path="/thankyou" element={<Thankyou />} />
                </>
              )}
            </Routes>
          </Top>
        </Router>
      </IntercomProvider>
    </>
  );
}

export default App;
