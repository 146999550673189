import React from 'react'
import "./Loader.css";
import load from "../../assets/Ellipse 14.svg";

export default function Loader() {
  return (
    <div className='loader_cont'>
        <div className="loader_center">
          <p>Please wait...</p>
            <img src={load} alt="" />
        </div>
    </div>
  )
}
