import React, { useState } from "react";
import "./ErrorPopup.css"; // Import CSS styles
import Modal from "react-modal";

Modal.setAppElement("#root");

const ErrorPopup = ({ isOpen, onClose, title }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modal Popup"
      className="modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <h2>Can't Subscribe!</h2>
        <br></br>
        <p>{title}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
};

export default ErrorPopup;
